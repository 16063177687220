$primary-color:  var(--primary-color);
$accent-color: #EBEEEF;
$border-color-light: #CBCBCB;
$main-background-color: #f9f9f9;
$hover-background-color: #E0E0E0;
$disabled-background-color: #E5E5E5;
$disabled-color: #B2B2B2;
$button-background-color: var(--button-background-color);
$button-color: var(--contrast-color);
$contrast-color: var(--contrast-color);
$text-color: var(--text-primary-color);
$icons-color: var(--icons-color);
$text-primary: var(--text-primary-darker-color);
