/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2RhMWEyNCIsIj9lcjwjZjRiYWJkIiwiO2VyPCNjYjBmMTZ$LCIlPmBePCNlYmVlZWYiLCI~ZXI8I2Y5ZmFmYSIsIjtlcjwjZTJlN2U4fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/
@use '@angular/material' as mat;


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.define-typography-level(18px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);


@include mat.all-component-typographies($fontConfig);
@include mat.core();

// Theme Config

body.standard{
  --primary-color: #DAFF00;
  --primary-lighter-color: #F6FFBA;
  --primary-darker-color: #C4E500;
  --text-primary-color: #001E18;
  --text-primary-lighter-color: #213B36;
  --text-primary-darker-color: #001E18;
  --button-background-color: #DAFF00;
  --contrast-color: #001E18;
  --icons-color: #001E18;
}

$mat-primary: (
    main: var(--icons-color),
    lighter: var(--primary-lighter-color),
    darker: var(--icons-color),
    200: var(--icons-color), // For slide toggle,
    contrast : (
      main: var(--contrast-color),
      lighter: var(--contrast-color),
      darker:var(--contrast-color),
    )
  );

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #ebeeef;
  --accent-lighter-color: #f9fafa;
  --accent-darker-color: #e2e7e8;
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{rgba(black, 0.87)};
}
$mat-accent: (
  main: var(--icons-color),
    lighter: var(--primary-lighter-color),
    darker: var(--icons-color),
    200: var(--icons-color), // For slide toggle,
    contrast : (
      main: var(--contrast-color),
      lighter: var(--contrast-color),
      darker:var(--contrast-color),
    )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
);
$my-typography: mat.define-typography-config();
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);;

$nx-theme: mat.define-light-theme((
 color: (
   primary: $theme-primary,
   accent: $theme-accent,
   warn: $theme-warn
 ),
  typography: $my-typography,
));

// Theme Init
@include mat.all-component-themes($nx-theme);

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

.mat-calendar-body-in-range::before {
  background: var(--primary-lighter-color) !important;
}
