/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "./app/shared-module/styles/variables.scss";
@import '../node_modules/lightgallery/scss/lightgallery.scss';


@import "./ng-theme.scss";
// @import "primeng/resources/themes/mdc-light-indigo/theme.css";
@import "primeng/resources/primeng.css";


/**
/ Custom prime ng css -> move to own file
**/


@layer tailwind-base, primeng, tailwind-utilities;
@layer tailwind-base {
  @tailwind base;
}
@layer primeng;
@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/**
/ icon testing
**/
.iconTesting {
  display: inline-block;
  line-height: 1;
}

.noScrollbar{
  -ms-overflow-style: none;  // IE und Edge
  scrollbar-width: none;     // Firefox

  &::-webkit-scrollbar {
    display: none;          // Chrome, Safari und Opera
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto";
  --mdc-typography-font-family: "Roboto";
}

.inner-main-component-wrapper {
  margin-top: 30px;
}

.icon {
  font-size: 32px;
  color: $icons-color;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin-left: 0;
  margin-right: 0;
}


/** Layout **/
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}

/** mat checkbox checked **/
.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: $primary-color !important;

  svg path {
    fill: $primary-color !important;
    stroke: $contrast-color !important;
  }
}

.active {
  background-color: $primary-color !important;
  color: $contrast-color !important;
  opacity:1;
  .icon {
    color: $contrast-color !important;
  }
  .mat-mdc-list-item-unscoped-content {
   // background-color: $contrast-color !important;
   color: $contrast-color !important;
  }
}


/** mat Slide togglebar checked **/

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow{
  background-color: white !important;
}

.mat-mdc-slide-toggle-checked .mdc-switch:enabled .mdc-switch__shadow{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.mat-mdc-slide-toggle-checked .mdc-switch__track::after {
  background-color: $contrast-color !important;
}
.mat-mdc-slide-toggle .mdc-switch__track::before {
  background-color: #808080 !important;
}
.mat-mdc-slide-toggle-checked .mdc-switch:enabled .mdc-switch__track::after {
  background-color: #e4e5e5 !important;
}
.mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 0 !important;
}

.mat-mdc-slide-toggle  .mdc-switch__icon {
  fill: $contrast-color !important;
}

.mdc-switch--selected:not([disabled]) .mdc-switch__handle::after{
  content:none !important;
}

// alle mat-icon, li oder elements mit class button oder link
.mat-icon,
.button,
.link {
  cursor: pointer ! important;
}


.mat-mdc-dialog-container {
  padding: 0px !important;
  overflow-x: hidden !important;
}
.mat-mdc-dialog-content {
  margin: 10px !important;
  padding: 0px !important;
  overflow-x: hidden !important;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
}

.mat-mdc-dialog-actions {
  margin: 10px !important;
  .mdc-button {
    color: $button-color !important;
    background-color: $button-background-color !important;
  }
  .mdc-button:disabled {
    background-color: $disabled-background-color !important;
    color: $disabled-color!important;
  }
  .mdc-button:hover {
    filter: brightness(85%);
  }
}

.mat-mdc-dialog-title {
  background-color: $primary-color !important;
  color: $contrast-color !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: #848488!important;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #001E18 !important;
}

.mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-color: #001E18;
}

::-webkit-scrollbar {
  background-color: white !important;
  width: 3px !important;
}

::-webkit-scrollbar-thumb {
  background: $accent-color;
}

.dialog-close {
  float: right;
  color: $contrast-color !important;
  padding: 13px;
}
// Tabellen Colum Border right
.border-right {
  border-right: 1px solid $border-color-light;
}

.no-data {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #C1C1C1;
}


.mat-cell {
  align-self: stretch !important;
}

.mat-calendar-body-selected{
  color: $contrast-color !important;
  background-color: $primary-color !important;
}

.mat-calendar-body-cell-container:hover {
      .mat-calendar-body-cell-content{
        color: $contrast-color !important;
        background-color: $primary-color !important;
      }
}

.mat-calendar-body-cell {
  &:focus {
    .mat-calendar-body-cell-content {
      color: $text-primary !important;
    }
  }
}

.mat-calendar{
  .mdc-button {
    background-color: white !important;
  }
}

.mat-calendar-body-today {
    background-color: white !important;
}

// Form field Appearence Standard
.mat-mdc-form-field-focus-overlay {
  background-color: $accent-color !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: $accent-color !important;
}

// Button color and padding
.mat-mdc-unelevated-button {
  color: $contrast-color !important;
  margin: 10px !important;
  padding:  0 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

 .mdc-text-field--disabled.mdc-text-field--filled,  .mdc-text-field{
  background-color: $accent-color !important;
 }

 .mat-mdc-tooltip .mdc-tooltip__surface {
  background-color:  #EAEEEF !important;
  color: black !important;
  font-size: small !important;
  border-radius: 4px !important;
  text-align: left !important;
  white-space: pre-line !important;
}

.headline i{
  font-size: 32px;
  color: $icons-color;
}

.headline, .headline h2{
  margin-left: 20px !important;
  display: inline-flex;
  margin-bottom: 0px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.table_header {
  height: 30px !important;
  min-height: 30px !important;
  background-color: white !important;
  // padding-left: 70px !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.table_row {
  height: 48px !important;
  background-color: white !important;
  border-bottom: 1px solid #e5e7eb !important;

}

.table_row:hover{
  background: #EBEEEF !important;
  cursor: pointer !important;
}

.header_column{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black !important;
  // padding-left: 20px !important;
}

.table_column {
  padding-left: 27px !important;
}

.mat-drawer-container {
  max-width: 100% !important;
  //background-color: white !important;
  // height: 100% !important;
}

.filter {
  width: 240px;
  padding: 25px;
  // background-color: #ebeeef !important;
  mat-form-field {
    width: 100%;
  }
  .mdc-text-field {
    padding: 0px;
  }
}

[hidden] {
  display: none !important;
}

.spinner {
  min-height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-menu-item .mat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mdc-button {
  min-height: 30px;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  color: $button-color !important;
  background-color: $button-background-color !important;
}
.mdc-button:disabled {
  background-color: $disabled-background-color !important;
  color: $disabled-color!important;
}
// .mdc-button:hover {
//   filter: brightness(85%);
// }

.mat-pseudo-checkbox-checked {
  background-color: $primary-color !important;
}
.mat-pseudo-checkbox-minimal {
  background-color: #f5f5f5 !important;
}

.mat-pseudo-checkbox-full::after {
  color: $contrast-color !important;
  width: 8px !important;
  height: 3px !important;
}

.middle {
  font-weight: bold;
  padding: 20px;
}

.mdc-list {
  padding: 0px !important;
}

.string { color: green; text-wrap: wrap; }
.number { color: rgba(172, 171, 235, 0.959) }
.boolean { color: blue; }

.dense-1, .dense-2, .dense-3{
  .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
  }
}

.dense-3 .mat-mdc-form-field-infix {
  min-height: 37px !important;
}

.dense-3 .mdc-text-field {
  border-radius: 5px !important;
}

.dense-3 .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 6px !important;
  padding-bottom: 2px !important;
  font-size: 14px;
  margin-left: 2px;
}

 .white{

  .mdc-text-field--filled {
    background-color: white !important;
  }
  .mdc-text-field--disabled.mdc-text-field--filled,  .mdc-text-field{
    background-color: white !important;
   }
}
::ng-deep {
.mdc-button:disabled, .mat-mdc-button[disabled], .mat-mdc-button:disabled {
  background-color: $disabled-background-color !important;
  color: $disabled-color !important;
}
}

.mat-mdc-button {
  font-weight: 500 !important;
  letter-spacing: normal !important;
}

.mat-mdc-header-cell {
  font-family: 'Roboto' !important;
}

.mat-mdc-cell, .mat-mdc-list-base  {
  font-family: 'Roboto' !important;
}

.mat-mdc-paginator {
  font-family: 'Roboto' !important;
}

.menu {
  background-color: #EBEEEF !important;
}
.mdc-list-item__primary-text{
  display: flex !important;
}

.protocols .mat-expansion-panel-content {
  background-color: white;
}
.protocols .mat-expansion-panel-body, .protocols .mat-expansion-panel-header{
  padding-left: 0px !important;

}

.form .mdc-line-ripple {
  display: none;
}

.mdc-tab__text-label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
}

h3 {
  margin: 0px !important;
  font-weight: 500 !important;
}

.mat-mdc-input-element::placeholder {
  letter-spacing: 0px !important;
}



.icon-jump-to {
  font-size: 18px !important;
}

.mat-pseudo-checkbox {
  border-color: #e0e0e0 !important
}

.mat-pseudo-checkbox-checked {
  border-color: #011E18 !important;
}

.mat-mdc-form-field-hint-wrapper {
  opacity:50% !important;
}


.jump {
  display: inline-flex;
  align-items: center;
}

.disposition .mat-mdc-tab-body-wrapper {
  height: 100%;
}
.mat-drawer-container {
  height: 100%;
  // background-color: white !important;
}

.mat-mdc-fab {
  background-color: $primary-color !important;
  box-shadow: none !important;
}


.mat-badge-content {
  background: #8c9896 !important;
  color: white !important;
  right: -2px !important;
  top: 2px !important;
}


nx-editdialog {
  position: relative !important;
  z-index: 99 !important;
}
